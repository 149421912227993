import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useLookerData } from 'redux/state/looker'
import {
  Button,
  DateDropdown,
  DateDropdownPeriod,
  H4,
  H3,
  standardIcons,
} from '@chordco/component-library'
import { Metrics } from './Metrics'
import { SubSection } from './Home'
import { useAuthData } from 'redux/state/auth'
import { useIsMobile } from 'hooks'
import { AnalyticsBlurbBanner } from './AnalyticsBlurbBanner'
import { useExperiments } from 'hooks/useExperiments'
import { useHistory } from 'react-router'
import { useHasFailed, useIsLoading } from 'redux/utils'
import { EmptyMetrics } from './EmptyMetrics'
import { Loading } from 'components/common'

const { ArrowRight } = standardIcons

export const MetricsTiles = () => {
  const [period, setPeriod] = useState<DateDropdownPeriod>('week')

  const {
    state: { tiles },
    getTiles,
  } = useLookerData()

  const { state } = useAuthData()

  const isOmsAdmin = state.user?.role === 'oms_admin'

  const tilesTreatment = useExperiments('hub_tiles_status')

  const tilesEnabled = tilesTreatment?.variation === 'on'

  useEffect(() => {
    if (tilesEnabled) getTiles && getTiles(period)
  }, [period, tilesEnabled])

  const isMobile = useIsMobile()

  const history = useHistory()
  const goToAnalytics = () => history.push('/analytics')

  const loading = useIsLoading('getTiles')
  const hasError = useHasFailed('getTiles')

  return (
    <>
      <H3>Store Overview</H3>

      {tilesEnabled && (
        <DropdownContainer>
          <DateDropdown selected={period} onSelect={setPeriod} />
        </DropdownContainer>
      )}

      <SubSection isMobile={isMobile}>
        {!tilesEnabled && (
          <AnalyticsBlurbBanner
            message="Your data will show up once Looker has been configured."
            status="off"
          />
        )}

        {tilesEnabled && (
          <>
            <MetricsTitle>Performance</MetricsTitle>

            {loading ? (
              <Loading hideMask={true} opacity={1} />
            ) : (
              <>
                {hasError ? (
                  <EmptyMetrics hasError={true} />
                ) : tiles.length ? (
                  <Metrics metrics={tiles} />
                ) : (
                  <EmptyMetrics />
                )}
              </>
            )}
          </>
        )}
      </SubSection>
      <ButtonContainer>
        {!isOmsAdmin && (
          <Button purpose="tertiary" trailingIcon={ArrowRight} onClick={goToAnalytics}>
            View All Analytics
          </Button>
        )}
      </ButtonContainer>
    </>
  )
}

const MetricsTitle = styled(H4)`
  margin: 16px 0;
`

const DropdownContainer = styled.div`
  margin-top: 16px;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  margin-bottom: 64px;
`
